import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';

const NotFoundPage = ({data}) => {
  const { footerData, headerData } = data;

  return (
    <Layout footerData={footerData} headerData={headerData}>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
} 

export const query = graphql`
  query FourOhFourPage {
    ...LayoutFragment
  }
`

export default NotFoundPage
